import 'ol/ol.css';
import {useGeographic} from 'ol/proj';
import {Map, View, Feature, Overlay} from 'ol/index';
import {Point} from 'ol/geom';
import {Vector as VectorLayer, Tile as TileLayer} from 'ol/layer';
import {OSM, Vector as VectorSource} from 'ol/source';
import {Style, Circle, Fill} from 'ol/style';

useGeographic();

var place = [56.19540, 57.99453];

var point = new Point(place);

var map = new Map({
  target: 'map',
  view: new View({
    center: place,
    zoom: 4
  }),
  layers: [
    new TileLayer({
      source: new OSM()
    }),
//   new VectorLayer({
//      source: new VectorSource({
//        features: [
//          new Feature(point)
//       ]
//      }),
      //style: new Style({
      //  image: new Circle({
      //    radius: 9,
      //    fill: new Fill({color: 'red'})
      //  })
      //})
//    })
  ]
});

var element = document.getElementById('popup');

var popup = new Overlay({
  element: element,
  positioning: 'bottom-center',
  stopEvent: false,
  offset: [0, -10]
});
map.addOverlay(popup);

//MY
function pad(num, count) {

var lenDiff = count - String(num).length;

var padding = "";

      

if (lenDiff > 0)

while (lenDiff--)

padding += "0";

      

return padding + num;

}
//end MY

function formatCoordinate(coordinate) {
//MY	
var plat = coordinate[1].toFixed(5)*1;
var plon = coordinate[0].toFixed(5)*1;

var ltr = (plat<0 ? "x" : "" ) + String.fromCharCode("A".charCodeAt(0) + Math.abs(plat) / 4); 
var sq001 = ltr + "-" + Math.floor((plon+180)/6+1).toFixed(0);
var sq500 = sq001 + "-" + (1 + 2   -  2*Math.floor(((plat+100)%4)/2)   + Math.floor(((plon+180)%6)/3) ).toFixed(0);
var sq200 = sq001 + "-" + pad( Math.ceil(1 + 30  -  6*Math.floor(((plat+100)%4)/2*3) + Math.floor(((plon+180)%6))),   2 );
var sq100 = sq001 + "-" + pad( Math.ceil(1 + 132 - 12*Math.floor(((plat+100)%4)*3)   + Math.floor(((plon+180)%6)*2)), 3 );
var sq050 = sq100 + "-" + (1 + 2   -  2*Math.floor(((plat+100)%(1/3))*6)   + Math.floor(((plon+180)%(1/2))*4) ).toFixed(0);
var sq025 = sq050 + "-" + (1 + 2   -  2*Math.floor(((plat+100)%(1/6))*12)   + Math.floor(((plon+180)%(1/4))*8) ).toFixed(0);
//end MY
  return ("\n    <table align=\"center\">\n      <tbody>\n        <tr><th>Широта: </th><td>" + (coordinate[1].toFixed(5)) + "</td></tr>\n        <tr><th>Долгота: </th><td>" + (coordinate[0].toFixed(5)) + "</td></tr>\n        </tbody><table><br> <div align=\"center\"><a  target=\"_PARENT\" href=https://satmaps.info/searchw-map.php?m250=" + sq025 + "&m2=" + sq200 + "&lat=" + plat + "&lng=" + plon + "><font color=blue>Просмотр доступных карт</font></a></div>");
}

var info = document.getElementById('info');
map.on('moveend', function() {
  var view = map.getView();
  var center = view.getCenter();
  info.innerHTML = formatCoordinate(center);
});

map.on('click', function(event) {
  var feature = map.getFeaturesAtPixel(event.pixel)[0];
  if (feature) {
    var coordinate = feature.getGeometry().getCoordinates();
    popup.setPosition(coordinate);
    $(element).popover({
      placement: 'top',
      html: true,
      content: formatCoordinate(coordinate)
    });
    $(element).popover('show');
  } else {
    $(element).popover('destroy');
  }
});

map.on('pointermove', function(event) {
  if (map.hasFeatureAtPixel(event.pixel)) {
    map.getViewport().style.cursor = 'pointer';
  } else {
    map.getViewport().style.cursor = 'inherit';
  }
});
